<template>
  <div class="order">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="输入昵称/手机号/卡券id搜索"
          @input="kwChange"
          clearable
          class="mr15"
        />
        <el-select
          v-model="tableParams.orderStatus"
          placeholder="请选择订单状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(orderStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.paymentType"
          placeholder="请选择支付方式"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(paymentOrderTypeObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.roomId"
          placeholder="请选择位置名称"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in roomList"
            :key="item.roomId"
            :label="item.roomName"
            :value="item.roomId"
          >
          </el-option>
        </el-select>
      </div>
      <el-date-picker
        v-model="dateToDate"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="~"
        start-placeholder="预约开始日期"
        end-placeholder="预约结束日期"
        :picker-options="pickerOptions"
        @change="reload"
        clearable
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <div class="search-R">
        <PlaceOrder :roomList="roomList" @reload="reload"></PlaceOrder>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="下单用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName || "-" }}</span>
          <span>丨</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置" align="center" min-width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{{ scope.row.roomName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="预约时间" align="center" min-width="140">
        <template slot-scope="scope">
          <span>{{ scope.row.startTime || "-" }}</span>
          <span>&nbsp;至&nbsp;</span>
          <span>{{ scope.row.endTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单来源" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.orderType == 1">散客</span>
          <span v-if="scope.row.orderType == 2">团建</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" align="center">
        <template slot-scope="scope">
          <span :style="{ color: orderStatusColor[scope.row.orderStatus] }">{{
            scope.row.orderStatusName || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实付金额 / 订单价格" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.orderType && scope.row.orderType != 2">
            <span>￥{{ scope.row.finalAmount || "0" }}</span>
            <span>&nbsp;/&nbsp;</span>
            <del>￥{{ scope.row.orderAmount || "0" }}</del>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="已使用卡券id" align="center"  prop="memberCardId">
        
      </el-table-column>
      <el-table-column label="支付方式" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.orderType != 2">{{
            scope.row.paymentInfo || "-"
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120">
        <template slot-scope="scope" v-if="scope.row.orderType == 1|| scope.row.orderType == 4">
          <el-button type="text" size="medium" @click.stop="onInfo(scope.row)"
            >订单详情</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.orderStatus == 2"
            size="medium"
            @click.stop="getOrderArriveInfo(scope.row)"
            >{{ startBtnTitle[scope.row.serviceTemplateType] }}</el-button
          >

          <el-button
            type="text"
            size="medium"
            @click.stop="onDel(scope.row)"
            v-if="scope.row.orderStatus == 2 || scope.row.orderStatus == 1"
            >取消订单</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onLeave(scope.row)"
            v-if="scope.row.orderStatus == 3"
            >{{ leaveBtnTitle[scope.row.serviceTemplateType] }}</el-button
          >
          <OrderRefund
            :orderId="scope.row.orderId"
            @reload="getTableList"
            v-if="scope.row.orderStatus != 1"
          >
          </OrderRefund>
          <OrderOperateList :orderId="scope.row.orderId"></OrderOperateList>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog
      title="订单详情"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="40%"
    >
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="80px"
        :disabled="params.orderStatus == 9 || params.orderStatus == 4"
      >
        <section class="form-main">
          <!-- 基础信息 -->
          <el-descriptions :column="3" border class="mb15">
            <el-descriptions-item label="订单状态">
              <span :style="{ color: orderStatusColor[params.orderStatus] }">
                {{ params.orderStatusName }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="下单用户">
              <span>{{ params.nickName || "-" }}</span>
              <span>丨</span>
              <span>{{ params.mobile || "-" }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="下单时间">{{
              params.addDt
            }}</el-descriptions-item>
            <el-descriptions-item label="订单门店">{{
              params.shopName
            }}</el-descriptions-item>
            <el-descriptions-item label="实付金额">{{
              params.finalAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="订单价格">{{
              params.orderAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="订单编号">{{
              params.orderId
            }}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{
              params.paymentTypeName
            }}</el-descriptions-item>
          </el-descriptions>
          <!-- 起止时间 -->
          <el-form-item label="起止时间" prop="serviceId">
            <el-date-picker
              v-model="timeToTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 位置名称 -->
          <el-form-item label="位置名称" prop="roomId">
            <el-select v-model="params.roomId" placeholder="请选择位置名称">
              <el-option
                v-for="item in roomList"
                :key="item.roomId"
                :label="item.roomName"
                :value="item.roomId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 备注信息 -->
          <el-form-item label="备注信息" prop="remark">
            <el-input
              type="textarea"
              v-model.trim="params.remark"
              autocomplete="off"
              maxlength="100"
              placeholder="请填写备注信息"
            ></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </section>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="startBtnTitle[currentRow.serviceTemplateType]"
      :visible.sync="showDoor"
      width="30%"
      center
    >
      <span v-if="advanceStatus==0">{{`是否确定帮客户执行${startBtnTitle[currentRow.serviceTemplateType]}动作？` }} </span>
      <div class="main mb10" v-if="advanceStatus==1||advanceStatus==2||advanceStatus==6"><span class="text">原预约时间 :</span> {{ arriveInfo.startTime+'~'+arriveInfo.endTime }}</div>
      <div class="main mb10" v-if="advanceStatus==1||advanceStatus==6"><span class="text">提前订单时间 ：</span>{{ arriveInfo.afterStartTime+'~'+arriveInfo.afterEndTime }}</div>
      <div class="main mb10 red" v-if="advanceStatus==1">是否确定帮客户提前开启订单？</div>
      <div class="main mb10 red" v-if="advanceStatus==2">当前位置正在使用中，不可提前开始</div>
      <div class="main mb10 red" v-if="advanceStatus==3">当前位置停用中，不可提前开始</div>
      <div class="main mb10 red" v-if="advanceStatus==5">当前门店未到营业时间，不可提前开始</div>

      <div class="main mb10 red" v-if="advanceStatus==6">提前服务未完成，如有需要请联系现场客服</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDoor = false">取 消</el-button>
        <el-button type="primary" :disabled="![0,1,6].some(item=>item==advanceStatus)" @click="goOrderAdvance"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  getIdroomList,
  getTableEdit,
  getTableDel,
  getLeave,
  orderArriveInfo,
  openDoor
} from "@/api/order/order";
import { ObjToOpt, formatterDate } from "@/utils/utils";
import { rules } from "@/db/rules";
import {
  pickerOptions,
  orderStatusObj,
  orderStatusColor,
  paymentOrderTypeObj,
  leaveBtnTitle,
  startBtnTitle,
} from "@/db/objs";
import OrderOperateList from "@/views/order/child/OrderOperateList";
import OrderRefund from "@/views/order/child/OrderRefund";
import PlaceOrder from "@/views/order/child/PlaceOrder";
export default {
  components: { OrderOperateList, OrderRefund, PlaceOrder },
  data() {
    return {
      leaveBtnTitle,
      startBtnTitle,
      ObjToOpt,
      pickerOptions,
      orderStatusObj,
      orderStatusColor,
      formatterDate,
      paymentOrderTypeObj,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        orderStatus: 3, //订单状态
      },
      // 弹框
      editShow: false, //弹框开关
      rules,
      params: {
        remark: "",
        roomId: "",
      },
      timeToTime: [], //起止日期
      dateToDate: [], //日期
      roomList: [], //服务列表
      arriveInfo: {},
      advanceStatus: 0,
      currentRow:{},
      showDoor:false
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  watch: {
    timeToTime(val) {
      if (val) {
        this.params.startTime = val[0];
        this.params.endTime = val[1];
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
  },
  mounted() {
    this.getTableList(); // 【请求】表格数据
    this.getIdroomList(); //【请求】位置列表
  },
  methods: {
    // 【请求】表格数据
    getTableList() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】位置列表
    getIdroomList() {
      let data = this.tableParams;
      getIdroomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },

    // 【请求】订单修改
    getTableEdit() {
      let data = {
        orderId: this.params.orderId,
        startTime: this.params.startTime,
        endTime: this.params.endTime,
        roomId: this.params.roomId,
        remark: this.params.remark,
      };
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】详情
    onInfo(row) {
      console.log("row: ", row);
      this.editShow = true;
      this.params = JSON.parse(JSON.stringify(row));
      this.timeToTime = [this.params.startTime, this.params.endTime];
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】离店
    onLeave(row) {
      let data = {
        orderId: row.orderId,
      };
      this.$confirm(
        this.leaveBtnTitle[row.serviceTemplateType] +
          "后订单将自动转为“已完成”，是否进行" +
          this.leaveBtnTitle[row.serviceTemplateType] +
          "操作?",
        this.leaveBtnTitle[row.serviceTemplateType],
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }
      ).then(() => {
        getLeave(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getTableList();
          }
        });
      });
    },
    // 到店，判断当前位置信息
    getOrderArriveInfo(row) {
      
      this.currentRow=row
      let data = {
        orderId: row.orderId,
      };
      
      orderArriveInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.arriveInfo = res.data;
          this.advanceStatus = res.data.advanceStatus;
          this.showDoor=true
        }
       
      
        // 0:正常到店 1:可提前  2:房间使用中 3:房间停用中 4:"超出范围 5:'店铺已打烊还未营业'，6：提前服务
     
      });
    },
   
    // 开门
    // 到店开始
    goOrderAdvance() {
      this.showDoor=false
        let params = {
          orderId: this.currentRow.orderId,
          startTime:
            this.advanceStatus == 0 ? "" : this.arriveInfo.afterStartTime + ":00",
          endTime:
            this.advanceStatus == 0 ? "" : this.arriveInfo.afterEndTime + ":00",
        };
      openDoor(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
          message: this.startBtnTitle[this.currentRow.serviceTemplateType] + "成功",
          type: "success",
        });
        this.openOrderStatus = false;
        
        setTimeout(() => {
          this.getTableList();
        }, 1000);
        }
      
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getTableEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        orderId: row.orderId,
      };
      let html = `
        <p>当前位置：${row.serviceName}-${row.roomName}</p>
        <p>预约时间：${row.startTime}~${row.endTime}</p>
        <p>你确定要取消订单吗?</p>
      `;
      this.$confirm(html, "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        getTableDel(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getTableList();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main{
  margin: 0 auto;
}
.mb10{
  margin-bottom: 10px;
}
.red{
  color: red;
}
.text{
  font-weight: 500;
  color: black;
}
.order {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.search-R {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
